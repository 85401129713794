import React, { useState, useEffect } from 'react';
import './carousel.css'; // Using the same CSS file as the main carousel
import img21 from '../../assets/WPCdoor.jpg';
import img22 from '../../assets/upvc2door.jpg';
import img23 from '../../assets/Fiberdoor.jpg';
import img24 from '../../assets/cncdoor.jpg';

const CarouselDoor = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeRunning, setTimeRunning] = useState(true);

  const carouselData = [
    {
      imgSrc: img21,
      author: "Introducing",
      topic: "WPC Doors",
      description: "Upgrade your space with our premium WPC doors. Combining the elegance of wood with the durability of plastic for style, strength, and sustainability.",
    },
    {
      imgSrc: img22,
      author: "Introducing",
      topic: "uPVC Doors",
      description: "Discover the superior quality of our uPVC doors. Engineered to withstand the elements while providing unmatched security and insulation.",
    },
    {
      imgSrc: img23,
      author: "Introducing",
      topic: "Fiber Doors",
      description: "Experience the innovation of our fibre doors. Crafted with advanced materials for exceptional strength and durability with minimal maintenance.",
    },
    {
      imgSrc: img24,
      author: "Introducing",
      topic: "CNC and Routing Doors",
      description: "Explore the precision of our CNC and routing doors. Utilizing cutting-edge technology to bring your design vision to life with meticulous detail.",
    }
  ];

  const handleClick = () => {
    window.open('https://photos.app.goo.gl/rpGhXtbCqom7gmku8', '_blank');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRunning) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [timeRunning, carouselData.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    setTimeRunning(false);
    setTimeout(() => setTimeRunning(true), 10000); // Resume auto-rotation after 10 seconds
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
    );
    setTimeRunning(false);
    setTimeout(() => setTimeRunning(true), 10000); // Resume auto-rotation after 10 seconds
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    setTimeRunning(false);
    setTimeout(() => setTimeRunning(true), 10000); // Resume auto-rotation after 10 seconds
  };

  return (
    <div className="carousel">
      <div className="list">
        {carouselData.map((item, index) => (
          <div key={index} className={`item ${index === currentIndex ? 'active' : ''}`}>
            <img src={item.imgSrc} alt={item.topic} />
            <div className="content">
              <div className="author">{item.author}</div>
              <div className="topic">{item.topic}</div>
              <div className="des">{item.description}</div>
              <div className="buttons">
                <button onClick={handleClick}>View Gallery</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <div className="arrows">
        <button onClick={handlePrev}>&#8249;</button>
        <button onClick={handleNext}>&#8250;</button>
      </div>
      
      <div className="indicators">
        {carouselData.map((_, index) => (
          <div 
            key={index} 
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CarouselDoor;