import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import './slider.css';
import { SliderProducts } from "../../data/products";

const Slider = () => {
    const swiperRef = useRef(null);

    // Function to get Swiper instance
    const getSwiperInstance = () => {
        return swiperRef.current ? swiperRef.current.swiper : null;
    };

    const handleMouseEnter = () => {
        const swiper = getSwiperInstance();
        if (swiper) {
            swiper.autoplay.stop();
        }
    };

    const handleMouseLeave = () => {
        const swiper = getSwiperInstance();
        if (swiper) {
            swiper.autoplay.start();
        }
    };

    // Function to truncate text if needed
    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };

    return (
        <div className="s-container">
            <Swiper
                ref={swiperRef}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
                loop={true}
                slidesPerView={1}
                pagination={{ 
                    clickable: true,
                }}
                navigation={true}
                spaceBetween={10}
                speed={800}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 15
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                }}
                onSwiper={(swiper) => { swiperRef.current = { swiper }; }}
            >
                {SliderProducts.map((slide, i) => (
                    <SwiperSlide
                        key={i}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="slide-card">
                            <div className="slide-image-wrapper">
                                <img src={slide.img} alt={slide.name} className="slide-image" />
                            </div>
                            <div className="slide-info">
                                <div className="slide-title-area">
                                    <h3 className="slide-title">{truncateText(slide.name, 30)}</h3>
                                </div>
                                <div className="slide-description-area">
                                    <p className="slide-description">{truncateText(slide.detail, 60)}</p>
                                </div>
                                <div className="slide-button-area">
                                    <a href={slide.link} className="slide-button" target="_blank" rel="noopener noreferrer">
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Slider;