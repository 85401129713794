import React, { useState, useEffect } from 'react';
import './carousel.css';
import img11 from '../../assets/openaluwin.jpg';
import img12 from '../../assets/mosqitonetwindow.jpg';
import img13 from '../../assets/slideopenwindow.jpg';
import img14 from '../../assets/woodtexture.jpg';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeRunning, setTimeRunning] = useState(true);

  const carouselData = [
    {
      imgSrc: img11,
      author: "Introducing",
      topic: "Openable Windows",
      description: "Seamlessly blending modern aesthetics with functionality, these windows offer smooth operation for easy airflow and light control.",
    },
    {
      imgSrc: img12,
      author: "Introducing",
      topic: "Foldable Mosquito Net",
      description: "The stylish and practical solution to keep bugs out while enjoying fresh air and natural light without the hassle of traditional nets.",
    },
    {
      imgSrc: img13,
      author: "Introducing",
      topic: "Sliding Openable Door",
      description: "Elevate your space with our Sliding and Openable Windows and Doors. Enjoy effortless transitions between indoor and outdoor living.",
    },
    {
      imgSrc: img14,
      author: "Introducing",
      topic: "Wooden Texture Coating",
      description: "Immerse your surfaces in the warmth of natural wood with our wooden texture powder coating for a timeless look with modern durability.",
    }
  ];

  const handleClick = () => {
    window.open('https://photos.app.goo.gl/RMoZqgctfQb1Px559', '_blank');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRunning) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [timeRunning, carouselData.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    setTimeRunning(false);
    setTimeout(() => setTimeRunning(true), 10000); // Resume auto-rotation after 10 seconds
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
    );
    setTimeRunning(false);
    setTimeout(() => setTimeRunning(true), 10000); // Resume auto-rotation after 10 seconds
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    setTimeRunning(false);
    setTimeout(() => setTimeRunning(true), 10000); // Resume auto-rotation after 10 seconds
  };

  return (
    <div className="carousel">
      <div className="list">
        {carouselData.map((item, index) => (
          <div key={index} className={`item ${index === currentIndex ? 'active' : ''}`}>
            <img src={item.imgSrc} alt={item.topic} />
            <div className="content">
              <div className="author">{item.author}</div>
              <div className="topic">{item.topic}</div>
              <div className="des">{item.description}</div>
              <div className="buttons">
                <button onClick={handleClick}>View Gallery</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <div className="arrows">
        <button onClick={handlePrev}>&#8249;</button>
        <button onClick={handleNext}>&#8250;</button>
      </div>
      
      <div className="indicators">
        {carouselData.map((_, index) => (
          <div 
            key={index} 
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;